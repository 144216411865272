

















































import { Component, Vue, Prop } from 'vue-property-decorator';
import SectionTitleEditor from '@/components/editor/SectionEditor/SectionTitleEditor.vue';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { textToOps } from '@/utils/quill-delta.util';
import { EditorCommonMixin } from '@/views/DocumentEditor/mixins/editor-common.mixin';
import AuthorForm from '@/components/editor/SectionEditor/AuthorSectionEditor/AuthorsForm.vue';
import { Author } from '../../../../jbi-shared/types/author.types';
import { formatAuthors } from '../../../../jbi-shared/util/document.utils';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';

@Component({
  components: {
    SectionTitleEditor,
    AuthorForm
  }
})
export default class AuthorSectionEditor extends mixins(ViewModeMixin) {
  @Prop(String) public title!: string;
  @Prop(Boolean) public disabled!: boolean;
  @Prop(Number) public authorSectionId!: number;
  @Prop({
    default: () => []
  })
  public unformattedAuthors!: string[];
  @Prop()
  public dirtyAuthors!: FullDocumentRevisionObject['revision']['sections']['authorSubSections'];

  get sectionTitle() {
    return textToOps(this.title);
  }

  public openModal() {
    this.$buefy.modal.open({
      parent: this,
      component: AuthorForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Author Details',
        authors: this.dirtyAuthors,
        authorSectionId: this.authorSectionId
      },
      events: {
        'update:authors': (
          e: FullDocumentRevisionObject['revision']['sections']['authorSubSections']
        ) => this.$emit('update:authors', e)
      }
    });
  }

  get formattedAuthors(): string[] {
    return formatAuthors(this.dirtyAuthors.map((a) => a.content));
  }
}
