





































































import { Component, Prop, Watch, Vue, Inject } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { ValidationObserver } from 'vee-validate';
import SingleAuthorForm from '@/components/editor/SectionEditor/AuthorSectionEditor/SingleAuthorForm.vue';
import { Author } from '../../../../jbi-shared/types/author.types';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '../../../../utils/viewMode.mixin';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { EditorCommonMixin } from '@/views/DocumentEditor/mixins/editor-common.mixin';
import {
  generateDraftAuthor,
  generateRandomId
} from '@/jbi-shared/util/document.utils';

@Component({
  components: {
    ValidationObserver,
    SingleAuthorForm
  }
})
export default class AuthorsForm extends mixins(ViewModeMixin) {
  @Prop(String) public modalTitle!: string;
  @Prop({
    default() {
      return [];
    }
  })
  public authors!: FullDocumentRevisionObject['revision']['sections']['authorSubSections'];

  @Prop(Number) public authorSectionId!: number;

  public iDirtyAuthors: FullDocumentRevisionObject['revision']['sections']['authorSubSections'] = this
    .authors.length
    ? cloneDeep(this.authors)
    : [generateDraftAuthor(this.authorSectionId)];

  public reset() {
    this.iDirtyAuthors = cloneDeep(this.authors);
  }

  public addNewAuthor() {
    this.iDirtyAuthors.push(generateDraftAuthor(this.authorSectionId));
  }

  public removeAuthor(i: number) {
    this.iDirtyAuthors = this.iDirtyAuthors.filter((_, j) => j !== i);
  }

  public async saveAuthors() {
    const iDirtyAuthors = this.iDirtyAuthors;
    this.$emit('update:authors', iDirtyAuthors);
    this.$emit('close');
  }

  get isNew() {
    return this.authors.length === 0;
  }
}
