var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"max:50","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Title","type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},[_c('b-autocomplete',{attrs:{"data":_vm.titleSuggesstion},model:{value:(_vm.value.content.title),callback:function ($$v) {_vm.$set(_vm.value.content, "title", $$v)},expression:"value.content.title"}},[_c('template',{slot:"empty"},[_vm._v(" No suggestions found ")])],2)],1)]}}])})],1),_c('div',{staticClass:"column"})]),_c('ValidationProvider',{attrs:{"rules":"required|max:255","name":"First Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"First Name","type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},[_c('b-input',{model:{value:(_vm.value.content.firstName),callback:function ($$v) {_vm.$set(_vm.value.content, "firstName", $$v)},expression:"value.content.firstName"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required|max:255","name":"Last Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Last Name","type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},[_c('b-input',{model:{value:(_vm.value.content.lastName),callback:function ($$v) {_vm.$set(_vm.value.content, "lastName", $$v)},expression:"value.content.lastName"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"max:255","name":"Qualification"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{class:_vm.$style.qualificationInput,attrs:{"label":"Qualification","type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.value.content.qualification),callback:function ($$v) {_vm.$set(_vm.value.content, "qualification", $$v)},expression:"value.content.qualification"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }