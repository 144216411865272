




































































import { Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { mixins } from 'vue-class-component';
import { EditorCommonMixin } from '../../../../views/DocumentEditor/mixins/editor-common.mixin';
import { Author } from '../../../../jbi-shared/types/author.types';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { generateDraftAuthor } from '@/jbi-shared/util/document.utils';
import { titleSuggestion } from '@/jbi-shared/util/cplus-author.utils';

@Component({
  components: {
    ValidationProvider
  }
})
export default class SingleAuthorForm extends mixins(EditorCommonMixin) {
  @Prop({
    default() {
      return generateDraftAuthor(0);
    }
  })
  public value!: FullDocumentRevisionObject['revision']['sections']['authorSubSections'][0];

  get titleSuggesstion() {
    return titleSuggestion(this.value.content.title);
  }
}
